import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { ActivityType } from 'api/schema';
import {
  DEFAULT_SEARCH_PREFERENCES,
  UserSearchPreferences,
} from 'api/Serializers/Accounts';
import {
  Activity,
  ActivityData,
  ActivitySegment,
  City,
  CityListItem,
} from 'api/Serializers/Activities';
import { AppointmentProduct } from 'api/Serializers/AppointmentProducts';
import { Availability } from 'api/Serializers/Availability';
import {
  FacilityDetailSerializer,
  FacilityGoogleReviewSerializer,
  FacilityInstructorSerializer,
  FacilityListItemSerializer,
} from 'api/Serializers/Facilities';
import { InstructorDetailSerializer } from 'api/Serializers/Instructors';
import { InstructorReview } from 'api/Serializers/Reviews';
import {
  DATE_FMT,
  DAYS_PER_AVAILABILITY_PAGE,
  FETCH_STATE,
  SORT_OPTIONS,
} from 'config';
import {
  AvailabilityFetchError,
  GenericServerError,
  RegionNotFound,
} from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { APIData, RootState } from 'state';
import { getSearchFacilitySlug, getSearchPreferences } from 'state/selectors';
import { BLANK_API_DATA, getInitialStateFromServer } from 'state/slice/utils';
import { AppDispatch } from 'state/store';

type AvailabilityRecords = Record<string, Availability[]>;

interface Reducer {
  activity: Activity;
  city: City;
  cities: CityListItem[];
  segments: ActivitySegment[];
  slugs: {
    city: string;
    facility: string;
    instructor: string;
  };
  fetchStates: {
    facilities: string;
    facility: string;
    instructors: string;
    instructor: string;
    instructorReviews: string;
  };
  facilities: FacilityListItemSerializer[];
  facility: FacilityDetailSerializer;
  facilityGoogleReviews: {
    count: number;
    reviews: FacilityGoogleReviewSerializer[];
  };
  instructors: FacilityInstructorSerializer[];
  instructor: InstructorDetailSerializer;
  instructorReviews: InstructorReview[];
  searchPreferences: UserSearchPreferences;
  appointmentProducts: APIData<AppointmentProduct[]>;
  apptProdAvailability: AvailabilityRecords;
  apptProdAvailabilityFetchStates: Record<string, FETCH_STATE>;
  bookMoreApptProdIds: string[];
  bookMoreSorting: string;
}

const initialState: Reducer = {
  activity: undefined,
  segments: undefined,
  cities: undefined,
  city: undefined,
  facilities: [],
  facility: undefined,
  facilityGoogleReviews: { count: undefined, reviews: [] },
  instructors: [],
  instructor: undefined,
  instructorReviews: [],
  searchPreferences: DEFAULT_SEARCH_PREFERENCES,
  fetchStates: {
    facilities: FETCH_STATE.IDLE,
    facility: FETCH_STATE.IDLE,
    instructors: FETCH_STATE.IDLE,
    instructor: FETCH_STATE.IDLE,
    instructorReviews: FETCH_STATE.IDLE,
  },
  slugs: {
    city: undefined,
    facility: undefined,
    instructor: undefined,
  },
  appointmentProducts: BLANK_API_DATA([]),
  apptProdAvailability: {},
  apptProdAvailabilityFetchStates: {},
  bookMoreApptProdIds: [],
  bookMoreSorting: SORT_OPTIONS[0].value,
};

const name: 'search' = 'search';
const Slice = createSlice({
  name,
  initialState: getInitialStateFromServer(name, initialState),
  reducers: {
    setSearchCity(state, action: PayloadAction<City>) {
      if (!action.payload) {
        state.city = undefined;
        state.slugs = {
          city: undefined,
          facility: undefined,
          instructor: undefined,
        };
      } else if (!state.city || state.city.slug !== action.payload.slug) {
        const city = action.payload;
        state.city = city;
        state.slugs.city = city.slug;
        state.facilities = [];
      }
    },
    facilityListRequest(state, action: PayloadAction<string>) {
      Slice.caseReducers.clearSearchFacility(state);
      state.fetchStates.facilities = FETCH_STATE.GET;
    },
    facilityListFailed(state, action: PayloadAction<string>) {
      Slice.caseReducers.clearSearchFacility(state);
      state.fetchStates.facilities = FETCH_STATE.FAILED;
    },
    setSearchFacilities(
      state,
      action: PayloadAction<FacilityDetailSerializer[]>
    ) {
      state.facilities = action.payload || [];
      state.fetchStates.facilities = FETCH_STATE.FULFILLED;
    },
    instructorListRequest(state) {
      state.fetchStates.instructors = FETCH_STATE.GET;
    },
    instructorDetailRequest(state, action: PayloadAction<string>) {
      state.fetchStates.instructor = FETCH_STATE.GET;
      state.instructor = undefined;
      state.slugs.instructor = action.payload;
    },
    setSearchInstructors(
      state,
      action: PayloadAction<FacilityInstructorSerializer[]>
    ) {
      state.instructors = action.payload;
      state.fetchStates.instructors = FETCH_STATE.IDLE;
    },
    setSearchInstructor(
      state,
      action: PayloadAction<InstructorDetailSerializer>
    ) {
      const instructor = action.payload;
      state.instructor = instructor;
      state.slugs.instructor = instructor.slug;
      state.fetchStates.instructor = FETCH_STATE.IDLE;
    },
    setSearchInstructorReviews(
      state,
      action: PayloadAction<InstructorReview[]>
    ) {
      state.instructorReviews = action.payload;
      state.fetchStates.instructorReviews = FETCH_STATE.IDLE;
    },
    clearSearchFacilities(state) {
      Slice.caseReducers.clearSearchFacility(state);
      state.facilities = [];
      state.slugs.city = undefined;
    },
    clearSearchFacility(state) {
      state.facility = undefined;
      state.facilityGoogleReviews.reviews = [];
      state.facilityGoogleReviews.count = undefined;
      state.instructor = undefined;
      state.instructors = [];
      state.slugs.facility = undefined;
      state.slugs.instructor = undefined;
    },
    facilityDetailRequest(state, action: PayloadAction<string>) {
      Slice.caseReducers.clearSearchFacility(state);
      state.slugs.facility = action.payload;
      state.fetchStates.facility = FETCH_STATE.GET;
    },
    setSearchFacility(state, action: PayloadAction<FacilityDetailSerializer>) {
      const facility = action.payload;
      state.facility = facility;
      state.fetchStates.facility = FETCH_STATE.IDLE;
      state.slugs.facility = facility.slug;
    },
    setFacilityGoogleReviews(
      state,
      action: PayloadAction<{
        count: number;
        reviews: FacilityGoogleReviewSerializer[];
      }>
    ) {
      state.facilityGoogleReviews = action.payload;
    },
    appendFacilityGoogleReviews(
      state,
      action: PayloadAction<FacilityGoogleReviewSerializer[]>
    ) {
      state.facilityGoogleReviews = {
        ...state.facilityGoogleReviews,
        reviews: state.facilityGoogleReviews.reviews.concat(action.payload),
      };
    },
    setAppointmentProductsFetchState(
      state,
      action: PayloadAction<FETCH_STATE>
    ) {
      state.appointmentProducts.fetchState = action.payload;
    },
    upsertAppointmentProducts(
      state,
      action: PayloadAction<AppointmentProduct[]>
    ) {
      state.appointmentProducts.data = state.appointmentProducts.data
        .filter((elt) => action.payload.every((inner) => inner.id !== elt.id))
        .concat(action.payload);
    },
    setApptProdAvailability(
      state,
      action: PayloadAction<{
        appointmentProductId: string;
        availability: Availability[];
      }>
    ) {
      state.apptProdAvailability[action.payload.appointmentProductId] =
        action.payload.availability;
    },
    addApptProdAvailability(
      state,
      action: PayloadAction<{
        appointmentProductId: string;
        availability: Availability[];
      }>
    ) {
      const existing =
        state.apptProdAvailability[action.payload.appointmentProductId] ?? [];
      state.apptProdAvailability[action.payload.appointmentProductId] = [
        ...existing,
        ...action.payload.availability,
      ];
    },
    deleteApptProdAvailability(
      state,
      action: PayloadAction<{ appointmentProductId: string }>
    ) {
      delete state.apptProdAvailability[action.payload.appointmentProductId];
    },
    clearAvailability(state) {
      const data = {};
      const fetchStates = {};
      for (let id in state.apptProdAvailability) {
        data[id] = [];
      }
      for (let id in state.apptProdAvailabilityFetchStates) {
        fetchStates[id] = FETCH_STATE.PRISTINE;
      }
      state.apptProdAvailability = data;
      state.apptProdAvailabilityFetchStates = fetchStates;
    },
    setApptProdAvailabilityFetchState(
      state,
      action: PayloadAction<{
        appointmentProductId: string;
        fetchState: FETCH_STATE;
      }>
    ) {
      state.apptProdAvailabilityFetchStates[
        action.payload.appointmentProductId
      ] = action.payload.fetchState;
    },
    setPreferences(
      state,
      action: PayloadAction<Partial<UserSearchPreferences>>
    ) {
      state.searchPreferences = {
        ...state.searchPreferences,
        ...action.payload,
      };
    },
    clearPreferences(state) {
      state.searchPreferences = DEFAULT_SEARCH_PREFERENCES;
    },
    setDefaultSearch(state, action: PayloadAction<ActivityData>) {
      state.activity = action.payload.activity;
      state.cities = action.payload.cities;
      state.segments = action.payload.segments;
    },
    clearSearchApptProdIds(state) {
      state.bookMoreApptProdIds = initialState.bookMoreApptProdIds;
    },
    setSearchApptProdIds(state, action: PayloadAction<string[]>) {
      state.bookMoreApptProdIds = action.payload;
    },
    addSearchApptProdIds(state, action: PayloadAction<string[]>) {
      state.bookMoreApptProdIds = state.bookMoreApptProdIds.concat(
        action.payload
      );
    },
    setSearchSorting(state, action: PayloadAction<string>) {
      state.bookMoreSorting = action.payload;
    },
  },
});

export const {
  setSearchCity,
  setSearchInstructor,
  setSearchInstructorReviews,
  setSearchFacilities,
  clearSearchFacilities,
  setFacilityGoogleReviews,
  setSearchInstructors,
  setSearchFacility,
  setAppointmentProductsFetchState,
  setApptProdAvailability,
  upsertAppointmentProducts,
  deleteApptProdAvailability,
  setSearchApptProdIds,
  addSearchApptProdIds,
  setSearchSorting,
} = Slice.actions;

const {
  instructorDetailRequest,
  instructorListRequest,
  facilityDetailRequest,
  appendFacilityGoogleReviews,
  facilityListRequest,
  facilityListFailed,
  setPreferences,
  setDefaultSearch,
  addApptProdAvailability,
  setApptProdAvailabilityFetchState,
  clearAvailability,
  clearSearchApptProdIds,
  clearPreferences,
} = Slice.actions;

const getSearchPreferencesQueryParams = (
  preferences: UserSearchPreferences,
  constructor: { [key: string]: string } = {}
) => {
  const params = new URLSearchParams(constructor);
  let segment = preferences.segment?.value;
  if (segment !== undefined) {
    params.set('segment', segment);
  }
  if (preferences.times.length > 0) {
    preferences.times.forEach((elt) =>
      params.append('time', `${elt.weekDay}-${elt.start}-${elt.end}`)
    );
  }
  return params;
};

const clearSearchData = () => (dispatch: AppDispatch, getState) => {
  dispatch(clearSearchApptProdIds());
  dispatch(clearAvailability());
};

export const removeSearchPreferences =
  () => (dispatch: AppDispatch, getState) => {
    dispatch(clearSearchData());
    return dispatch(clearPreferences());
  };

export const setSearchPreferences =
  (preferences: UserSearchPreferences) =>
  async (dispatch: AppDispatch, getState) => {
    dispatch(clearSearchData());
    dispatch(setPreferences(preferences));
  };

export const fetchSearchAppointmentProduct =
  (appointmentProductId: string) => async (dispatch: AppDispatch, getState) => {
    dispatch(setAppointmentProductsFetchState(FETCH_STATE.GET));
    try {
      const response = await api.appointmentProducts.retrieve(
        appointmentProductId
      );
      dispatch(upsertAppointmentProducts([response.data]));
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.FULFILLED));
    } catch (e) {
      enqueueSnackbar(AvailabilityFetchError);
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.FAILED));
    }
  };

export const fetchFacilityList =
  (region: string) => async (dispatch: AppDispatch, getState) => {
    if (typeof region === 'undefined') {
      return null;
    }
    dispatch(facilityListRequest(region));
    try {
      const response = await api.facilities.list({ region });
      return dispatch(setSearchFacilities(response.data));
    } catch (error: any) {
      if (error.cancelled) {
        return null;
      }
      dispatch(facilityListFailed());
      return enqueueSnackbar(RegionNotFound);
    }
  };

export const fetchFacilityDetail =
  (slug: string) => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const currentFacilitySlug = getSearchFacilitySlug(state);
    if (slug === undefined || slug === currentFacilitySlug) {
      return;
    }
    dispatch(facilityDetailRequest(slug));
    try {
      const responses = await Promise.all([
        api.facilities.detail(slug),
        api.facilities.googleReviews(slug, { minRating: 5 }),
      ]);
      dispatch(setSearchFacility(responses[0].data));
      dispatch(setFacilityGoogleReviews(responses[1].data));
    } catch (error: any) {
      enqueueSnackbar(GenericServerError);
    }
  };

export const fetchFacilityGoogleReviewsNextPage =
  () => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const slug = getSearchFacilitySlug(state);
    const params = {
      minRating: 5,
      offset: state.search.facilityGoogleReviews.reviews.length,
    };
    try {
      const response = await api.facilities.googleReviews(slug, params);
      dispatch(appendFacilityGoogleReviews(response.data.reviews));
    } catch (error) {
      enqueueSnackbar({
        message: 'Failed to fetch Google Reviews',
        variant: 'error',
      });
    }
  };

const getFacilityInstructorsQueryParams = (dispatch: AppDispatch, getState) => {
  const state = getState();
  const preferences = getSearchPreferences(state);
  let segment = preferences.segment?.value;
  let times = undefined;
  if (preferences?.times?.length > 0) {
    times = preferences.times.reduce(
      (agg, val, ind) =>
        val.start && val.end
          ? `${agg}_${val.weekDay}-${val.start}-${val.end}`
          : agg,
      ''
    );
  }
  return {
    times,
    segment,
  };
};

export const fetchFacilityInstructors =
  (slug: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (slug === undefined) {
      return null;
    }
    dispatch(setSearchInstructors([]));
    dispatch(instructorListRequest());
    try {
      const searchParams = dispatch(getFacilityInstructorsQueryParams);
      const params = {
        ...searchParams,
        facility: slug,
      };
      const response = await api.instructors.list(params);
      return dispatch(setSearchInstructors(response.data));
    } catch (error) {
      enqueueSnackbar(GenericServerError);
      return null;
    }
  };

export const fetchInstructorData =
  (instructorSlug: string, facilitySlug: string) =>
  async (dispatch: AppDispatch, getState) => {
    if (instructorSlug === undefined || facilitySlug === undefined) {
      return null;
    }
    dispatch(instructorDetailRequest(instructorSlug));
    Promise.all([
      api.instructors.retrieve(instructorSlug, {
        facility: facilitySlug,
      }),
      api.instructors.reviews(instructorSlug),
    ])
      .then((responses) => {
        dispatch(setSearchInstructor(responses[0].data));
        dispatch(setSearchInstructorReviews(responses[1].data));
      })
      .catch((error) => {
        enqueueSnackbar(GenericServerError);
      });
  };

export const fetchApptProdAvailability =
  (
    appointmentProductId: string,
    params: { [key: string]: string } = {
      daysLimit: (DAYS_PER_AVAILABILITY_PAGE * 5).toString(),
    }
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    let appointmentProduct = state.search.appointmentProducts.data.find(
      (ap) => ap.id === appointmentProductId
    );
    if (appointmentProduct === undefined) {
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.GET));
      try {
        const apResponse = await api.appointmentProducts.retrieve(
          appointmentProductId
        );
        dispatch(upsertAppointmentProducts([apResponse.data]));
        dispatch(setAppointmentProductsFetchState(FETCH_STATE.FULFILLED));
      } catch (err) {
        enqueueSnackbar(AvailabilityFetchError);
        dispatch(setAppointmentProductsFetchState(FETCH_STATE.FAILED));
        return;
      }
    }
    const queryParams = getSearchPreferencesQueryParams(
      state.search.searchPreferences,
      {
        aptProdId: appointmentProductId,
        start: moment().format(DATE_FMT.DATE_FIELD),
        isBookable: 'true',
        ...params,
      }
    );
    try {
      dispatch(
        setApptProdAvailabilityFetchState({
          appointmentProductId,
          fetchState: FETCH_STATE.GET,
        })
      );
      const response = await api.availability.list(queryParams);
      dispatch(
        setApptProdAvailability({
          appointmentProductId,
          availability: response.data,
        })
      );
      dispatch(
        setApptProdAvailabilityFetchState({
          appointmentProductId,
          fetchState: FETCH_STATE.FULFILLED,
        })
      );
    } catch (err) {
      enqueueSnackbar(AvailabilityFetchError);
      dispatch(
        setApptProdAvailabilityFetchState({
          appointmentProductId,
          fetchState: FETCH_STATE.FAILED,
        })
      );
    }
  };

export const fetchApptProdAvailabilityNextPage =
  (appointmentProductId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    let appointmentProduct = state.search.appointmentProducts.data.find(
      (ap) => ap.id === appointmentProductId
    );
    const existingAvailability =
      state.search.apptProdAvailability[appointmentProductId];
    if (
      appointmentProduct === undefined ||
      existingAvailability === undefined ||
      existingAvailability.length === 0
    ) {
      dispatch(fetchApptProdAvailability(appointmentProductId));
      return;
    }
    const latestFetchedDate = moment(
      existingAvailability[existingAvailability.length - 1].date
    );
    if (
      latestFetchedDate.isSameOrAfter(moment(appointmentProduct.lastAvailable))
    ) {
      return;
    }
    const queryParams = getSearchPreferencesQueryParams(
      state.search.searchPreferences,
      {
        aptProdId: appointmentProductId,
        start: latestFetchedDate.add(1, 'day').format(DATE_FMT.DATE_FIELD),
        isBookable: 'true',
      }
    );
    try {
      dispatch(
        setApptProdAvailabilityFetchState({
          appointmentProductId,
          fetchState: FETCH_STATE.GET,
        })
      );
      const response = await api.availability.list(queryParams);
      dispatch(
        addApptProdAvailability({
          appointmentProductId,
          availability: response.data,
        })
      );
      dispatch(
        setApptProdAvailabilityFetchState({
          appointmentProductId,
          fetchState: FETCH_STATE.FULFILLED,
        })
      );
    } catch (err) {
      enqueueSnackbar(AvailabilityFetchError);
      dispatch(
        setApptProdAvailabilityFetchState({
          appointmentProductId,
          fetchState: FETCH_STATE.FAILED,
        })
      );
    }
  };

export const fetchDefaultSearchData =
  (activity: ActivityType) => async (dispatch: AppDispatch, getState) => {
    const response = await api.activities.retrieve(activity);
    return dispatch(setDefaultSearch(response.data));
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
