import { Status } from 'api/Serializers/Accounts';
import { Activity } from 'api/Serializers/Activities';
import { FacilityDetailSerializer } from 'api/Serializers/Facilities';
import { InstructorDetailSerializer } from 'api/Serializers/Instructors';
import { InstructorReview } from 'api/Serializers/Reviews';
import AvailabilitySelect from 'components/availability-select';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Callout from 'components/callout';
import FavouriteButton from 'components/favourite-button';
import Link from 'components/link';
import Loading from 'components/loading';
import GoogleMap from 'components/map';
import Modal from 'components/modal';
import { QueryParams, REVIEWS_PER_PAGE, UserType } from 'config';
import ImageCarousel from 'containers/image-carousel';
import { SearchFilters } from 'containers/instructor-list';
import Page from 'containers/page';
import ReadMore from 'containers/read-more';
import ReviewsContainer from 'containers/reviews';
import useQuery from 'hooks/useQuery';
import { InstructorDetailMeta as Meta } from 'metadata';
import { RouteParams } from 'models/route';
import { AppointmentProductCard } from 'pages/book';
import FacilityErrorRedirect from 'pages/locations/facility-error';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import {
  getAccountDetail,
  getActivity,
  getSearchCity,
  getSearchFacility,
  getSearchInstructor,
  getSearchInstructorReviews,
  getUserType,
} from 'state/selectors';
import { canViewFacility } from 'utils/business-logic';
import { APP_ROUTES } from 'utils/routing';

const Section = ({ title, children }) => (
  <>
    <hr />
    <div>
      <h3 className="mb-2 font-bold text-gray-900 font-body">{title}</h3>
      {children}
    </div>
  </>
);

const Location = ({ facility }: { facility: FacilityDetailSerializer }) => {
  return (
    facility && (
      <Section title="Where I teach">
        <div>
          <h4 className="font-semibold text-gray-700 text-md">
            {facility.displayName}
          </h4>
          <div className="text-sm">
            <span>{`${facility.attributes.temperature}°C`}</span>
            <span>{' · '}</span>
            <span>{`${facility.attributes.maxLength} long`}</span>
            <span>{' · '}</span>
            <span>{`${facility.attributes.minDepth} to ${facility.attributes.maxDepth} deep`}</span>
          </div>
        </div>
        <p className="whitespace-pre-wrap">{facility.description}</p>
        <p>Access instructions are provided after your booking is complete.</p>
        <div className="overflow-hidden rounded-2xl">
          <GoogleMap latlng={facility.region.latlng} />
        </div>
      </Section>
    )
  );
};

const InstructorFacilityHeader = ({ activity, instructor, facility }) => {
  const specializations = [
    instructor.specialization1,
    instructor.specialization2,
    `${instructor.numDelivered} ${activity.appointmentNoun}s delivered`,
  ].filter((spec) => !!spec);
  return (
    <div>
      <div className="overflow-hidden rounded-2xl">
        <img src={facility?.media[0]?.url} />
      </div>
      <div className="flex flex-col items-center lg:flex-row">
        <Avatar
          src={instructor.avatar}
          className="-mt-20 border-4 lg:-mt-14"
          variant="circle"
          diameter={40}
          border
        />
        <div className="flex-shrink-0 sm:flex-1">
          <div className="flex flex-col text-center lg:text-left lg:mt-3 lg:ml-3">
            <h1 className="my-0 font-extrabold text-gray-800 lg:pr-10">
              {activity.name}s with {instructor.displayName}
            </h1>
            <h4 className="font-medium text-md">{facility?.displayName}</h4>
            <div className="text-sm">
              {instructor.segments.concat(specializations).join(' · ')}
            </div>
          </div>
        </div>
        <div className="absolute right-0 top-2">
          <FavouriteButton
            contentType="instructor"
            contentObject={instructor}
            variant="icon"
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

const Description = ({
  instructor,
}: {
  instructor: InstructorDetailSerializer;
}) => {
  const handleOpenChange = (open: boolean) => {
    const action = open ? 'open' : 'close';
    const trackObj = {
      id: instructor.id,
      displayName: instructor.displayName,
      slug: instructor.slug,
      status: instructor.status,
    };
    rudderanalytics.track(`Description ${action}`, { instructor: trackObj });
  };
  return (
    <div>
      <ReadMore initialHeight={132} onOpenChange={handleOpenChange}>
        <p className="whitespace-pre-wrap">{instructor.description}</p>
      </ReadMore>
    </div>
  );
};

const AdminMessage = ({ userType, status }) =>
  userType === UserType.Admin &&
  status !== Status.Active &&
  status !== Status.Inactive &&
  status !== Status.Stale && (
    <div className="fixed bottom-0 z-50 max-w-xs my-4 mb-10 shadow-lg center-x">
      <Callout>
        Viewing instructor as Admin. Current account status is {status}
      </Callout>
    </div>
  );

const VIEW_SCHEDULE_VALUE = 'viewSchedule';

export const InstructorInfo = ({
  activity,
  instructor,
  facility,
  reviews,
}: {
  activity: Activity;
  instructor: InstructorDetailSerializer;
  facility: FacilityDetailSerializer;
  reviews: InstructorReview[];
}) => {
  return (
    <div>
      <InstructorFacilityHeader
        activity={activity}
        instructor={instructor}
        facility={facility}
      />
      <Description instructor={instructor} />
      {reviews.length > 0 && (
        <Section title={`${reviews.length} Testimonials`}>
          <div className="max-w-full">
            <ReviewsContainer
              name={`Instructor ${instructor.displayName}`}
              type="testimonials"
              count={reviews.length}
              reviews={reviews}
              limit={REVIEWS_PER_PAGE}
            />
          </div>
        </Section>
      )}
      {instructor.media.length > 0 && (
        <Section title="Highlights">
          <ImageCarousel>
            {instructor.media.map(({ url }) => (
              <img key={url} src={url} />
            ))}
          </ImageCarousel>
        </Section>
      )}
      <Location facility={facility} />
    </div>
  );
};

const InstructorDetailComponent = () => {
  const activity = useSelector(getActivity);
  const history = useHistory();
  const match = useRouteMatch<RouteParams>();
  const query: any = useQuery();
  const city = useSelector(getSearchCity);
  const instructor = useSelector(getSearchInstructor);
  const account = useSelector(getAccountDetail);
  const facility = useSelector(getSearchFacility);
  const userType = useSelector(getUserType);
  const reviews = useSelector(getSearchInstructorReviews);
  const viewScheduleParam = query.get(QueryParams.Action);
  const { instructorSlug } = useParams<RouteParams>();
  const handleCloseSchedule = () => {
    query.delete(QueryParams.Action);
    const queryString = query.toString() === '' ? '' : `?${query.toString()}`;
    history.push(match.url + queryString);
  };

  if (!instructor || instructor.slug !== instructorSlug || !facility) {
    return <Loading />;
  } else if (!canViewFacility(facility, account)) {
    return <FacilityErrorRedirect />;
  }
  const status = instructor.status;
  const appointmentProduct = instructor.appointmentProducts[0];

  let desktopCTA = 'Select a time';
  let mobileCTA = 'View availability';

  if (
    status === Status.Active ||
    status === Status.Inactive ||
    userType === UserType.Admin
  ) {
    return (
      <Page {...Meta(facility, instructor)}>
        <div className="max-w-6xl p-4 mx-auto sm:py-8 sm:px-6 lg:px-8">
          {city && facility && instructor && (
            <div className="pb-4 space-x-2 text-sm">
              <Link to={APP_ROUTES.BROWSE_FACILITY(city.slug, facility.slug)}>
                {facility.displayName}
              </Link>
              <span>{'>'}</span>
              <span>{instructor.displayName}</span>
            </div>
          )}
          <div className="flex md:space-x-4 lg:space-x-8">
            <div className="w-full md:w-[calc(100vw_-_26rem)] lg:w-[calc(100vw_-_30rem)] md:max-w-2xl space-y-8">
              <InstructorInfo
                facility={facility}
                instructor={instructor}
                activity={activity}
                reviews={reviews}
              />
            </div>
            <div className="hidden md:block w-[22rem] lg:w-96">
              <div className="sticky p-3 space-y-4 border border-gray-400 shadow-md top-6 sm:p-6 rounded-2xl">
                <div className="flex items-baseline justify-between">
                  <div>
                    <h2>{desktopCTA}</h2>
                    <h4 className="font-semibold text-gray-700 text-md">
                      {`$${
                        appointmentProduct.price
                      }/${activity.appointmentNoun.toLowerCase()}`}
                    </h4>
                  </div>
                  <SearchFilters allowedFilters={['times']} />
                </div>
                <AvailabilitySelect
                  appointmentProductId={appointmentProduct.id}
                  source="EXPLORE"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sticky bottom-0 left-0 right-0 z-50 bg-white border-t-2 md:hidden">
          <div className="">
            <div className="px-4 py-4">
              <div className="flex items-center justify-between">
                <div className="text-center text-gray-800">
                  <div className="font-bold text-md">
                    {instructor.numBookable}
                  </div>
                  <div className="text-xs text-gray-700">Open spots</div>
                </div>
                <div>
                  <Button
                    to={`${match.url}?${QueryParams.Action}=${VIEW_SCHEDULE_VALUE}`}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    {mobileCTA}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          name="Booking — View instructor schedule"
          title={`Book lessons with ${instructor.displayName}`}
          open={viewScheduleParam === VIEW_SCHEDULE_VALUE}
          onClose={handleCloseSchedule}
          fullScreen={true}
        >
          <div className="space-y-6">
            <div className="mx-auto -my-6">
              <AppointmentProductCard variant="mini" />
            </div>
            <SearchFilters allowedFilters={['times']} />
            <AvailabilitySelect
              appointmentProductId={appointmentProduct.id}
              source="EXPLORE"
            />
          </div>
        </Modal>
        <AdminMessage userType={userType} status={status} />
      </Page>
    );
  } else {
    return (
      <div className="max-w-lg my-8">
        <Callout type="error" title="Instructor no longer available">
          Looks like that instructor isn't currently teaching with Propel! Don't
          worry{' — '}
          there are many others ready to teach.
        </Callout>
      </div>
    );
  }
};

export default InstructorDetailComponent;
