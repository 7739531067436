import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import { AppointmentProduct } from 'api/Serializers/AppointmentProducts';
import { FacilityScheduleSerializer } from 'api/Serializers/Facilities';
import {
  ScheduleChange,
  ScheduleChangeAction,
  ScheduleChangeSerializer,
  ScheduleDateSerializer,
  WeeklySchedule,
} from 'api/Serializers/Schedules';
import axios, { AxiosError } from 'axios';
import { DATE_FMT, FETCH_STATE, WeekdayLower } from 'config';
import { GenericServerError } from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { enqueueSnackbar } from 'notistack';
import { APIData } from 'state';
import {
  getActivity,
  getAppointmentProductsFetchedAt,
  getCurrentUser,
  getScheduleAppointmentProduct,
  getScheduleAppointmentProducts,
  getScheduleChanges,
  getScheduleOpenings,
  getScheduleRenderDate,
  getScheduleWeeklySchedule,
} from 'state/selectors';
import { LocalStore } from 'state/storage';
import { AppDispatch } from 'state/store';
import { clearAppointmentFetchStates } from '../appointments';
import { clearAvailabilityFetchStates } from '../availability';
import { clearProposalsFetchStates } from '../proposals';
import { BLANK_API_DATA, getInitialStateFromStorage } from '../utils';

export enum ScheduleMode {
  Default = '',
  View = 'view',
  Weekly = 'weekly',
  Availability = 'availability',
  FacilitySchedules = 'schedules',
  LearnAvailability = 'learn-availability',
}

interface Reducer {
  listMode: 'date' | 'month';
  renderDate: string;
  filterDate: string;
  changes: ScheduleChange[];
  weeklySchedule: WeeklySchedule[];
  facilityWeeklySchedules: Record<string, FacilityScheduleSerializer[]>;
  appointmentProduct: AppointmentProduct;
  appointmentProducts: APIData<AppointmentProduct[]>;
  openings: APIData<ScheduleDateSerializer[]>;
}

const initialState: Reducer = {
  renderDate: moment().startOf('month').format(DATE_FMT.DATE_KEY),
  filterDate: moment().format(DATE_FMT.DATE_KEY),
  listMode: 'date',
  changes: [],
  weeklySchedule: [],
  facilityWeeklySchedules: {},
  appointmentProduct: undefined,
  appointmentProducts: BLANK_API_DATA([]),
  openings: BLANK_API_DATA([]),
};

/**
 * reshapeData:
 * Changing appointmentProducts from an array to object was causing
 * issues when the localStorage saved state was being input as an array.
 * This caused the state machine to expect an object, but be met with
 * an array. This function is run when ingesting the localStorage
 * saved state and modifies it appropriately.
 */
const stateAttributesWithNewShape = ['appointmentProducts', 'openings'];
const reshapeData = (data: Reducer): Reducer => {
  stateAttributesWithNewShape.map((key) => {
    if (data.hasOwnProperty(key) && typeof data[key].length !== 'undefined') {
      data[key] = BLANK_API_DATA(data[key]);
    }
  });
  return data;
};

const name: 'schedule' = 'schedule';
const Slice = createSlice({
  name,
  initialState: {
    ...getInitialStateFromStorage(name, initialState, reshapeData),
    facilityWeeklySchedules: initialState.facilityWeeklySchedules, // don't want to cache these
  },
  reducers: {
    setRenderDate(state, action: PayloadAction<string>) {
      state.renderDate = action.payload;
      if (moment(action.payload).isSame(moment(), 'month')) {
        state.filterDate = moment().format(DATE_FMT.DATE_KEY);
      } else {
        state.filterDate = action.payload;
      }
      LocalStore.set('renderDate', state.renderDate);
      LocalStore.set('filterDate', state.filterDate);
    },
    setScheduleFilterDate(state, action: PayloadAction<string>) {
      state.filterDate = action.payload;
      LocalStore.set('filterDate', state.filterDate);
    },
    setScheduleListMode(state, action: PayloadAction<'date' | 'month'>) {
      state.listMode = action.payload;
    },
    clearWeeklySchedules(state) {
      state.weeklySchedule = [];
    },
    newWeeklySchedule(
      state,
      action: PayloadAction<{
        start: string;
        end: string;
        aptProdId: string;
        schedule: FacilityScheduleSerializer;
      }>
    ) {
      const { start, end, aptProdId, schedule } = action.payload;
      const newSchedule: WeeklySchedule = {
        start,
        end,
        aptProdId,
        schedule,
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      };
      if (
        state.weeklySchedule.some(
          (sched) => sched.start === newSchedule.start
        ) === false
      ) {
        state.weeklySchedule.push(newSchedule);
      }
    },
    setWeekday(
      state,
      action: PayloadAction<{
        start: string;
        weekday: WeekdayLower;
        hours: string[];
      }>
    ) {
      const { start, weekday, hours } = action.payload;
      const i = state.weeklySchedule.findIndex((ws) => ws.start === start);
      state.weeklySchedule[i][weekday] = hours;
    },
    setFacilityWeeklySchedules(
      state,
      action: PayloadAction<{
        facilitySlug: string;
        weeklySchedules: FacilityScheduleSerializer[];
      }>
    ) {
      state.facilityWeeklySchedules[action.payload.facilitySlug] =
        action.payload.weeklySchedules;
    },
    addChange(state, action: PayloadAction<ScheduleChange>) {
      if (state.changes.find((c) => c.datetime === action.payload.datetime)) {
        // Remove from changes
        state.changes = state.changes.filter(
          (c) => c.datetime !== action.payload.datetime
        );
      } else {
        // Add to changes
        state.changes.push(action.payload);
        state.changes.sort((a, b) => (a.datetime > b.datetime ? 1 : -1));
      }
    },
    setAppointmentProduct(state, action: PayloadAction<AppointmentProduct>) {
      state.changes = [];
      state.openings.data = [];
      state.appointmentProduct = action.payload;
    },
    setAppointmentProductsData(
      state,
      action: PayloadAction<AppointmentProduct[]>
    ) {
      state.appointmentProducts.data = action.payload;
    },
    setAppointmentProductsFetchState(
      state,
      action: PayloadAction<FETCH_STATE>
    ) {
      if (action.payload === FETCH_STATE.GET) {
        state.appointmentProducts.fetchedAt = moment().format(
          DATE_FMT.DATETIME_FIELD
        );
      }
      state.appointmentProducts.fetchState = action.payload;
    },
    setOpeningsData(state, action: PayloadAction<ScheduleDateSerializer[]>) {
      state.openings.data = action.payload;
    },
    setOpeningsFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.openings.fetchState = action.payload;
    },
    flushChanges(state) {
      state.changes = [];
      state.weeklySchedule = [];
    },
    clearScheduleChanges(state) {
      state.changes = [];
      state.weeklySchedule = [];
    },
  },
});

const {
  setRenderDate,
  flushChanges,
  addChange,
  newWeeklySchedule,
  setWeekday,
  setAppointmentProductsData,
  setAppointmentProductsFetchState,
  setOpeningsData,
  setOpeningsFetchState,
} = Slice.actions;

export const {
  clearWeeklySchedules,
  setScheduleFilterDate,
  setScheduleListMode,
  setAppointmentProduct,
  clearScheduleChanges,
  setFacilityWeeklySchedules,
} = Slice.actions;

export const setScheduleRenderDate =
  (renderDate: string) => (dispatch: AppDispatch, getState) => {
    const test = /^(\d){4}-([0123]?[0-9])-([0123]?[0-9])$/;
    if (!test.test(renderDate)) {
      renderDate = moment().startOf('month').format(DATE_FMT.DATE_KEY);
    }
    renderDate = moment(renderDate).startOf('month').format(DATE_FMT.DATE_KEY);
    if (renderDate === getScheduleRenderDate(getState())) {
      return null;
    }
    dispatch(clearAppointmentFetchStates());
    dispatch(clearAvailabilityFetchStates());
    dispatch(clearProposalsFetchStates());
    return dispatch(setRenderDate(renderDate));
  };

export const fetchAppointmentProducts =
  (force?: boolean) => async (dispatch, getState) => {
    try {
      const state = getState();
      const lastFetchedAt = getAppointmentProductsFetchedAt(state);
      if (
        !force &&
        lastFetchedAt &&
        Math.abs(moment().diff(lastFetchedAt, 'minutes')) < 30
      ) {
        return null;
      }
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.GET));
      const response = await api.appointmentProducts.list();
      dispatch(setAppointmentProductsData(response.data));
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.FULFILLED));
      return response;
    } catch (err) {
      logger.error(err);
      enqueueSnackbar(GenericServerError);
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.FAILED));
      return undefined;
    }
  };

export const addScheduleChange = (change: ScheduleChange) => {
  return addChange({
    ...change,
    created: moment().format(DATE_FMT.DATETIME_FIELD),
  });
};

let abortController: AbortController;
export const fetchOpenings =
  (force = false) =>
  async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const renderDate = getScheduleRenderDate(state);
    const appointmentProduct = getScheduleAppointmentProduct(state);
    if (!appointmentProduct) {
      return null;
    }
    // Set the fetchedAt value right away so that subsequent
    // calls to fn do not initiate the same api call
    const minDate = moment();
    const start = (
      moment(renderDate).startOf('month').isAfter(minDate)
        ? moment(renderDate).startOf('month')
        : minDate
    ).format(DATE_FMT.DATE_KEY);
    const end = moment(renderDate).endOf('month').format(DATE_FMT.DATE_KEY);
    if (minDate.isAfter(end, 'date')) {
      return null;
    }
    dispatch(setOpeningsFetchState(FETCH_STATE.GET));
    const aptProdId = appointmentProduct.id;
    let response;
    if (abortController) {
      abortController.abort();
    }
    try {
      abortController = new AbortController();
      response = await api.schedules.openings(
        { start, end, aptProdId },
        abortController
      );
      dispatch(setOpeningsData(response.data));
      dispatch(setOpeningsFetchState(FETCH_STATE.IDLE));
      return response?.data;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return null;
      }
      logger.captureAxiosError('Error fetching openings', error);
      dispatch(setOpeningsFetchState(FETCH_STATE.FAILED));
      return null;
    }
  };

export const saveScheduleChanges =
  () => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const user = getCurrentUser(state);
    const changes = getScheduleChanges(state);
    if (changes.length === 0) {
      return null;
    }
    dispatch(setOpeningsFetchState(FETCH_STATE.PUT));
    const uniqueAptProdDates: Array<{ date: string; aptProdId: string }> = [];
    const formattedChanges: ScheduleChangeSerializer[] = [];
    for (const change of changes) {
      const aptProdIndex = uniqueAptProdDates.findIndex(
        (aptProdDate) =>
          aptProdDate.date === change.date &&
          aptProdDate.aptProdId === change.aptProdId
      );
      if (aptProdIndex === -1) {
        const date = change.date;
        const aptProdId = change.aptProdId;
        uniqueAptProdDates.push({ date, aptProdId });
      }
      let j = formattedChanges.findIndex((c) => c.id === change.aptProdId);
      if (j === -1) {
        const newChange: ScheduleChangeSerializer = {
          id: change.aptProdId,
          toCreate: [],
          toDelete: [],
        };
        j = formattedChanges.push(newChange) - 1;
      }
      if (change.action === ScheduleChangeAction.Create) {
        formattedChanges[j].toCreate = formattedChanges[j].toCreate.concat(
          change.datetime
        );
      } else if (change.action === ScheduleChangeAction.Delete) {
        formattedChanges[j].toDelete = formattedChanges[j].toDelete.concat(
          change.datetime
        );
      } else {
        continue;
      }
    }
    try {
      const response = await api.schedules.put(user.username, formattedChanges);
      dispatch(flushChanges());
      dispatch(setOpeningsFetchState(FETCH_STATE.GET));
      // Patch the .openings data
      await Promise.all(
        uniqueAptProdDates.map((aptProdDate) =>
          api.schedules.times(user.username, aptProdDate)
        )
      ).then((responses) =>
        responses.map((response) => {
          const data = response.data;
          const state = getState();
          const openings = getScheduleOpenings(state);
          const newOpenings = [...openings].filter(
            (opening) => opening.date !== data.date
          );
          newOpenings.push(data);
          newOpenings.sort((a, b) => (a.date > b.date ? 1 : -1));
          return dispatch(setOpeningsData(newOpenings));
        })
      );
      dispatch(setOpeningsFetchState(FETCH_STATE.IDLE));
      return response;
    } catch (error: any) {
      dispatch(setOpeningsFetchState(FETCH_STATE.FAILED));
      logger.captureAxiosError('Error updating schedule changes', error);
      return false;
    }
  };

export const addNewWeeklySchedule = (
  start: string,
  end: string,
  aptProdId: string,
  schedule: FacilityScheduleSerializer
) => newWeeklySchedule({ start, end, aptProdId, schedule });

export const updateWeekdaySchedule = (
  start: string,
  weekday: WeekdayLower,
  hours: string[]
) => setWeekday({ start, weekday, hours });

export const submitWeeklySchedules =
  (onSuccess, onError?) => async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const username = getCurrentUser(state).username;
    const data = getScheduleWeeklySchedule(state).filter(
      (sched) =>
        !sched.schedule.publicAccessDate ||
        moment().isSameOrAfter(sched.schedule.publicAccessDate, 'date')
    );
    try {
      const response = await api.schedules.weekly.create(username, data);
      dispatch(clearWeeklySchedules());
      onSuccess(response);
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

export const fetchFacilityWeeklySchedules =
  (facilitySlug: string) => async (dispatch: AppDispatch, getState) => {
    try {
      const response = await api.facilities.schedules(facilitySlug, {
        start: moment().format(DATE_FMT.DATE_KEY),
        end: moment().add(2, 'years').format(DATE_FMT.DATE_KEY),
      });
      dispatch(
        setFacilityWeeklySchedules({
          facilitySlug: facilitySlug,
          weeklySchedules: response.data,
        })
      );
    } catch (err) {
      enqueueSnackbar({
        message: 'Error fetching facility weekly schedules',
        variant: 'error',
      });
    }
  };

export const createAppointmentProduct =
  (facilityId: number, instructorId: number) => async (dispatch, getState) => {
    try {
      const state = getState();
      const activity = getActivity(state);
      const activityId = activity?.id ?? 1;
      const response = await api.appointmentProducts.create({
        activityId,
        facilityId,
        instructorId,
      });
      dispatch(
        setAppointmentProductsData([
          ...getScheduleAppointmentProducts(state),
          response.data,
        ])
      );
      return response.data;
    } catch (error) {
      enqueueSnackbar(GenericServerError);
      return undefined;
    }
  };

export const deleteAppointmentProduct =
  (aptProd: AppointmentProduct) => async (dispatch, getState) => {
    if (!aptProd) {
      return null;
    }
    const state = getState();
    const appointmentProducts = getScheduleAppointmentProducts(state);
    dispatch(setAppointmentProductsFetchState(FETCH_STATE.POST));
    let success = false;
    try {
      await api.appointmentProducts.delete(aptProd.id);
      const products = appointmentProducts.filter(
        (prod) => prod.id !== aptProd.id
      );
      dispatch(setAppointmentProductsData(products));
      success = true;
    } catch (error) {
      success = false;
      if (
        error instanceof AxiosError &&
        error.response.data.code === 'active_unlist_attempt_error'
      ) {
        enqueueSnackbar({
          variant: 'error',
          message:
            "Can't remove facility with future availability, proposals or bookings.",
        });
      } else {
        enqueueSnackbar({
          variant: 'error',
          message:
            'There was an error removing that facility.. Please refresh and try again.',
        });
      }
    } finally {
      dispatch(setAppointmentProductsFetchState(FETCH_STATE.IDLE));
      return success;
    }
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
