import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import * as Sentry from '@sentry/react';
import {
  AppointmentProduct,
  AppointmentProductStatus,
} from 'api/Serializers/AppointmentProducts';
import { Facility } from 'api/Serializers/Appointments';
import { ClientSerializer } from 'api/Serializers/Clients';
import Avatar from 'components/avatar';
import Button from 'components/button';
import ButtonLarge from 'components/button-large';
import Callout from 'components/callout';
import Controls from 'components/controls';
import Loading from 'components/loading';
import Modal from 'components/modal';
import {
  CAL_LIST_ITEM_NAME_PREFIX,
  DATE_FMT,
  FETCH_STATE,
  MAX_DAYS_UNTIL_PROPOSAL_EXPIRES,
  MAX_LATE_BOOKING_HOUR,
  MIN_HOURS_UNTIL_PROPOSAL_START,
} from 'config';
import FacilityScheduleUpdates from 'containers/facility-updates';
import { ScrollOptions } from 'features/schedule';
import TimeSlotList from 'features/schedule/as-instructor/base-time-slot-list';
import { ProposalCalendarDay } from 'features/schedule/calendar-date-box';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { EditIcon, FacilityIcon, SendIcon } from 'icons';
import {
  GenericServerError,
  InstructorProposalSubmitSuccess,
} from 'lang/en/Snackbars';
import moment from 'moment-timezone';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Prompt } from 'react-router-dom';
import { animateScroll, Element, scroller } from 'react-scroll';
import {
  getAppointmentProductsFetchState,
  getClientListFetchState,
  getFacilitiesSchedulableFetchState,
  getProposalClient,
  getProposalCreateList,
  getProposalCreateState,
  getProposalUpdate,
  getScheduleAppointmentProduct,
  getScheduleAppointmentProducts,
  getScheduleOpenings,
  getScheduleOpeningsFetchState,
  getScheduleRenderDate,
  getUsername,
} from 'state/selectors';
import { fetchAvailability } from 'state/slice/availability';
import {
  clearCreatedProposals,
  clearProposal,
  fetchProposals,
  flushProposal,
  saveProposals,
  setProposalClient,
  setProposalData,
} from 'state/slice/proposals';
import { fetchOpenings, setAppointmentProduct } from 'state/slice/schedule';
import { getMonthStartEndParams } from 'utils/date';
import { SHARED_ROUTES } from 'utils/routing';
import ScheduleTemplate from '../template';
import ClientList from './client-list';

enum Step {
  Unset,
  SelectClient,
  SelectFacility,
  SelectDateTimes,
  ConfirmProposal,
  ContinueOrComplete,
}

function scrollToTarget(target) {
  scroller.scrollTo(target, {
    smooth: 'easeInOutCubic',
    duration: 1000,
    offset: -24,
    containerId: 'ScheduleList',
  });
}

/**
 * Display a list of the instructor's clients
 */
const SelectClient = () => {
  return (
    <Modal
      name="Instructor — Select client for proposals"
      open={true}
      disableBackdropClick={true}
      title="Select Client"
    >
      <div className="w-full sm:w-96">
        <div className="mb-12 sm:mb-0">
          <ClientList />
        </div>
      </div>
    </Modal>
  );
};

const SelectFacility = ({ client, onClickCancel }) => {
  const appointmentProducts = useSelector(
    getScheduleAppointmentProducts
  )?.filter((prod) => prod.status === AppointmentProductStatus.Active);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appointmentProducts.length === 1) {
      dispatch(setAppointmentProduct(appointmentProducts[0]));
    }
  }, []);

  const handleAppointmentProductSelect = (aptProd: AppointmentProduct) => {
    dispatch(setAppointmentProduct(aptProd));
  };

  if (appointmentProducts.length === 1) {
    return <Loading message="Loading profiles..." />;
  }

  if (!client) {
    return null;
  }

  return (
    <Modal
      name="Instructor — Select facility for proposals"
      open={true}
      disableBackdropClick={true}
      maxWidth="sm"
      title="Select Facility"
    >
      <div className="space-y-6">
        <Callout type="question">Which facility is this for?</Callout>
        <div className="flex flex-col space-y-2">
          {appointmentProducts
            .sort((a, b) => {
              const indexA = client.facilities.indexOf(a.facility.id);
              const indexB = client.facilities.indexOf(b.facility.id);
              return indexA > indexB ? -1 : 1;
            })
            .map((appointmentProduct) => {
              const facility = appointmentProduct.facility;
              const hasBookedHere = client.facilities.indexOf(facility.id) > -1;
              return (
                <ButtonLarge
                  key={facility.slug}
                  title={facility.displayName}
                  subtitle={
                    hasBookedHere
                      ? `${client.displayName} recently booked here`
                      : null
                  }
                  icon={
                    <Avatar src={facility.avatar} diameter={8} border={true} />
                  }
                  onClick={() =>
                    handleAppointmentProductSelect(appointmentProduct)
                  }
                />
              );
            })}
        </div>
        <Controls>
          <Button color="default" variant="flat" onClick={onClickCancel}>
            Quit
          </Button>
        </Controls>
      </div>
    </Modal>
  );
};

interface SelectDateTimeProps {
  client: ClientSerializer;
  appointmentProduct: AppointmentProduct;
  onClickCancel(): void;
}

const SelectDateTimes = ({
  client,
  appointmentProduct,
}: SelectDateTimeProps) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitted, setSubmitted] = useState(false);
  const appointmentProducts = useSelector(
    getScheduleAppointmentProducts
  )?.filter((prod) => prod.status === AppointmentProductStatus.Active);
  const proposal = useSelector(getProposalUpdate);
  const proposals = useSelector(getProposalCreateList);
  const renderDate = useSelector(getScheduleRenderDate);
  const [isViewSummary, setViewSummary] = useState(false);
  const scheduleOpenings = useSelector(getScheduleOpenings);
  const minDate = moment();
  const maxDate = moment().add(6, 'months');
  const dispatch = useAppDispatch();
  const username = useSelector(getUsername);
  const fetchState = useSelector(getScheduleOpeningsFetchState);
  const [weeklySchedulesFacility, setWeeklySchedulesFacility] =
    useState<Facility>();
  const isLoading = fetchState === FETCH_STATE.GET;

  useEffect(() => {
    scroller.scrollTo('TopOfList', ScrollOptions);
    dispatch(fetchOpenings());
  }, [renderDate]);

  useEffect(() => {
    if (!!proposal && !!proposal.datetime) {
      handleProposalAdd();
    }
  }, [proposal]);

  const handleProposalAdd = () => {
    dispatch(flushProposal());
  };

  const handleSuccess = () => {
    rudderanalytics.track('Proposals Sent', {
      username,
      appointmentProductId: appointmentProduct.id,
      numProposals: proposals.length,
    });
    setSubmitted(false);
    enqueueSnackbar(InstructorProposalSubmitSuccess);
    const params = getMonthStartEndParams(renderDate);
    dispatch(fetchProposals(params, true));
    dispatch(fetchAvailability(params, true));
    history.push(SHARED_ROUTES.SCHEDULE.ROOT);
  };

  const handleError = (error) => {
    setSubmitted(false);
    enqueueSnackbar(GenericServerError);
    Sentry.captureEvent({
      message: 'Error encountered submitting Proposals',
      level: 'error',
      extra: {
        error,
        proposals,
      },
    });
  };

  const handleSubmit = () => {
    setSubmitted(true);
    dispatch(saveProposals(handleSuccess, handleError));
  };

  const handleChangeLocation = () => {
    dispatch(setAppointmentProduct(undefined));
    dispatch(clearCreatedProposals());
  };

  const handleChangeClient = () => {
    dispatch(setProposalClient(undefined));
  };

  const handleCloseSummary = () => {
    if (isLoading) {
      return null;
    }
    setViewSummary(false);
  };

  const handleDayClick = (date: string) => {
    if (moment(renderDate).isSame(date, 'month')) {
      const target = `${CAL_LIST_ITEM_NAME_PREFIX}-${date}`;
      scrollToTarget(target);
    }
  };

  if (!appointmentProduct || !client) {
    return null;
  }

  const timezone = appointmentProduct.timezone;

  return (
    <>
      {isLoading && <Loading message="Loading available times..." />}
      {isSubmitted && <Loading message="Sending proposals..." />}
      <ScheduleTemplate
        minDate={minDate}
        maxDate={maxDate}
        isLoading={isLoading}
        mode="proposals"
        Menu={() => (
          <>
            {appointmentProducts.length > 1 && (
              <Button
                onClick={handleChangeLocation}
                variant="flat"
                size="small"
                shape="rounded-xl"
                icon={<FacilityIcon width={20} />}
              >
                Location
              </Button>
            )}
            <Button
              onClick={handleChangeClient}
              variant="flat"
              size="small"
              shape="rounded-xl"
              icon={<EditIcon width={20} />}
            >
              <span className="hidden sm:inline">Change client</span>
              <span className="sm:hidden">Start over</span>
            </Button>
            <Button
              disabled={proposals.length === 0}
              onClick={() => setViewSummary(true)}
              color="primary"
              variant="contained"
              size="small"
              shape="rounded-xl"
              icon={<SendIcon width={20} />}
            >
              <span className="hidden sm:inline">Review & send</span>
              <span className="sm:hidden">Send</span>
            </Button>
          </>
        )}
        CalendarDayLoader={(props) => {
          const data = scheduleOpenings.find(
            (dt) => dt.date === props.date
          ) || {
            aptProdId: undefined,
            times: [],
            loading: false,
          };
          return (
            <ProposalCalendarDay
              {...data}
              {...props}
              onClick={handleDayClick}
            />
          );
        }}
      >
        <>
          {scheduleOpenings.map((scheduleDate, i) => {
            return (
              <Element
                key={scheduleDate.date}
                className="px-2 py-px sm:px-6"
                name={`${CAL_LIST_ITEM_NAME_PREFIX}-${scheduleDate.date}`}
              >
                <div
                  className={`p-6 mx-auto space-y-6 bg-white rounded-2xl max-w-lg`}
                >
                  <div>
                    <div className="flex gap-1">
                      <h2>{appointmentProduct?.facility.displayName}</h2>
                      <IconButton
                        size="small"
                        onClick={() =>
                          setWeeklySchedulesFacility(
                            appointmentProduct?.facility
                          )
                        }
                      >
                        <EllipsisHorizontalCircleIcon width={24} />
                      </IconButton>
                    </div>
                    <h5>
                      {moment(scheduleDate.date).format(DATE_FMT.DOW_MON_D)}
                    </h5>
                  </div>
                  <TimeSlotList {...scheduleDate} mode="proposals" />
                </div>
              </Element>
            );
          })}
          {scheduleOpenings.length > 5 && (
            <div className="px-2 py-px sm:px-6">
              <div
                className={`mx-auto items-center max-w-lg flex space-x-3 justify-between`}
              >
                <hr className="flex-1" />
                <button
                  className="italic text-gray-600 hover:underline"
                  onClick={() => scrollToTarget('TopOfList')}
                >
                  Back to top
                </button>
                <hr className="flex-1" />
              </div>
            </div>
          )}
        </>
      </ScheduleTemplate>
      <Modal
        name="Instructor — Review & send proposals"
        open={isViewSummary}
        onClose={handleCloseSummary}
        title="Review & Send"
        maxWidth="sm"
      >
        <div>
          <p>
            The following times will be reserved for {client?.firstName} until
            the expiry date shown.
          </p>
          <p>
            Proposed lessons reserve space at the pool, holding time for your
            client until they confirm or it expires.
          </p>
          <div className="">
            <h4>Proposals for {client?.fullName}</h4>
            {/* <EventParticipants event={participants} /> */}
            <div className="divide-y divide-gray-300">
              {proposals.map((proposal, i) => {
                let expiry;
                let bookingDeadline = moment(proposal.datetime).tz(timezone);
                bookingDeadline = appointmentProduct.facility.allowLateBookings
                  ? bookingDeadline
                      .subtract(1, 'days')
                      .startOf('day')
                      .hour(MAX_LATE_BOOKING_HOUR)
                  : bookingDeadline.subtract(
                      MIN_HOURS_UNTIL_PROPOSAL_START,
                      'hours'
                    );
                if (
                  moment()
                    .tz(timezone)
                    .add(MAX_DAYS_UNTIL_PROPOSAL_EXPIRES, 'days')
                    .isBefore(bookingDeadline)
                ) {
                  expiry = moment()
                    .tz(timezone)
                    .add(MAX_DAYS_UNTIL_PROPOSAL_EXPIRES, 'days')
                    .endOf('day');
                } else {
                  expiry = bookingDeadline;
                }
                return (
                  <div key={proposal.datetime} className="relative ">
                    <div className="flex flex-col justify-between py-6 space-y-2 text-sm text-gray-800 sm:flex-row md:py-3 sm:space-y-0">
                      <div className="relative flex flex-col col-span-12 flex-2 md:col-span-6">
                        <span className="absolute left-0 text-gray-600 uppercase text-xxs -top-2.5 md:hidden">
                          Description
                        </span>
                        <span className="font-medium">
                          {moment(proposal.datetime)
                            .tz(timezone)
                            .format(DATE_FMT.DOW_MONTH_D_TIME_A)}
                        </span>
                        <span className="text-gray-600">
                          Expires {expiry.format(DATE_FMT.MONTH_D_TIME_A)}
                        </span>
                      </div>
                      <div className="flex-1">
                        <div className="flex justify-center">
                          <span className="text-gray-700 bg-blue-200 badge">
                            Ready to be sent
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Controls>
            <Button
              disabled={isSubmitted}
              onClick={() => setViewSummary(false)}
              color="default"
              variant="flat"
            >
              Modify
            </Button>
            <Button
              disabled={isSubmitted}
              onClick={handleSubmit}
              color="primary"
              variant="flat"
            >
              Send proposals
            </Button>
          </Controls>
        </div>
      </Modal>
      <Modal
        name="Facility Weekly Schedules"
        title="Weekly Schedules"
        open={weeklySchedulesFacility !== undefined}
        onClose={() => setWeeklySchedulesFacility(undefined)}
        maxWidth="sm"
        fullWidth
      >
        <div>
          {weeklySchedulesFacility && (
            <FacilityScheduleUpdates
              facility={weeklySchedulesFacility}
              truncate={false}
            />
          )}
        </div>
      </Modal>
    </>
  );
};

const InstructorProposals = () => {
  const getStep = () => {
    if (!client) {
      return Step.SelectClient;
    } else if (client && !appointmentProduct) {
      return Step.SelectFacility;
    } else {
      return Step.SelectDateTimes;
    }
  };
  const appointmentProduct = useSelector(getScheduleAppointmentProduct);
  const client = useSelector(getProposalClient);
  const [step, setStep] = useState<Step>(getStep());
  const [cancelAttempt, setCancelAttempt] = useState(false);
  const fetchState = useSelector(getProposalCreateState);
  const proposals = useSelector(getProposalCreateList);
  const clientsFetchState = useSelector(getClientListFetchState);
  const facilityFetchState = useSelector(getFacilitiesSchedulableFetchState);
  const appointmentProductFetchState = useSelector(
    getAppointmentProductsFetchState
  );
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // When steps change, move scroll position back to top
    animateScroll.scrollToTop({ duration: 0 });
  }, [step]);
  useEffect(() => {
    dispatch(setProposalClient(undefined));
    return () => {
      dispatch(clearProposal());
      dispatch(setAppointmentProduct(undefined));
    };
  }, []);

  useEffect(() => {
    dispatch(
      setProposalData({
        aptProdId: appointmentProduct?.id,
        client: client?.id,
      })
    );
    setStep(getStep());
  }, [appointmentProduct, client]);

  const handleProposalDialogClose = () => {
    // When the Cancel button is clicked, we ask to confirm, then we clear the Proposal data completely
    if (
      cancelAttempt ||
      proposals.length === 0 ||
      fetchState === FETCH_STATE.FULFILLED
    ) {
      history.push(SHARED_ROUTES.SCHEDULE.ROOT);
    } else {
      setCancelAttempt(true);
    }
  };

  if (
    clientsFetchState !== FETCH_STATE.FULFILLED ||
    appointmentProductFetchState !== FETCH_STATE.FULFILLED ||
    facilityFetchState !== FETCH_STATE.FULFILLED
  ) {
    return <Loading />;
  }

  return (
    <>
      <Prompt
        when={proposals.length > 0}
        // DO NOT CHANGE FORMAT
        message={`Leave proposals?
You will lose any unsaved changes.`}
        // DO NOT CHANGE FORMAT
      />
      {step === Step.SelectClient ? (
        <SelectClient />
      ) : step === Step.SelectFacility ? (
        <SelectFacility
          client={client}
          onClickCancel={handleProposalDialogClose}
        />
      ) : step === Step.SelectDateTimes ? (
        <SelectDateTimes
          client={client}
          appointmentProduct={appointmentProduct}
          onClickCancel={handleProposalDialogClose}
        />
      ) : null}
      <Modal
        name="Instructor — Discard unsaved proposals"
        title="Incomplete Proposal"
        open={cancelAttempt}
        onClose={() => setCancelAttempt(false)}
        maxWidth="xs"
      >
        <Callout type="question">
          You have {proposals.length} unconfirmed{' '}
          {'proposal'.pluralize(proposals.length)}. Would you like to go back
          and finalize {proposals.length === 1 ? 'it' : 'them'} or discard and
          quit?
        </Callout>
        <Controls>
          <Button
            color="default"
            variant="flat"
            onClick={() => setCancelAttempt(false)}
          >
            Go back
          </Button>
          <Button
            color="secondary"
            variant="flat"
            onClick={handleProposalDialogClose}
          >
            Discard changes
          </Button>
        </Controls>
      </Modal>
    </>
  );
};

export default InstructorProposals;
