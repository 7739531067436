import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'api';
import {
  Availability,
  AvailabilityListParams,
} from 'api/Serializers/Availability';
import axios from 'axios';
import { DATE_FMT, FETCH_STATE } from 'config';
import moment from 'moment-timezone';
import { getScheduleRenderDate } from 'state/selectors';
import { getInitialStateFromStorage } from 'state/slice/utils';
import { AppDispatch } from 'state/store';

interface AvailabilityReducer {
  list: Availability[];
  listFetchState: FETCH_STATE;
  listFetchedAt: string;
}

const initialState: AvailabilityReducer = {
  list: [],
  listFetchState: FETCH_STATE.PRISTINE,
  listFetchedAt: undefined,
};

const name: 'availability' = 'availability';
const Slice = createSlice({
  name,
  initialState: getInitialStateFromStorage(name, initialState),
  reducers: {
    setList(state, action: PayloadAction<Availability[]>) {
      state.list = action.payload;
      state.listFetchState = FETCH_STATE.IDLE;
    },
    setListFetchState(state, action: PayloadAction<FETCH_STATE>) {
      state.listFetchState = action.payload;
    },
    setListFetchedAt(state, action: PayloadAction<string>) {
      state.listFetchedAt = action.payload;
    },
    clearAvailabilityFetchStates(state) {
      state.list = [];
      state.listFetchState = FETCH_STATE.PRISTINE;
      state.listFetchedAt = undefined;
    },
  },
});

// Local actions
const { setList, setListFetchState, setListFetchedAt } = Slice.actions;

// Global actions
export const { clearAvailabilityFetchStates } = Slice.actions;

let abortController: AbortController;
export const fetchAvailability =
  (params: AvailabilityListParams, force = false) =>
  async (dispatch: AppDispatch, getState) => {
    if (abortController) {
      abortController.abort();
    }
    try {
      abortController = new AbortController();
      dispatch(setListFetchState(FETCH_STATE.GET));
      dispatch(setListFetchedAt(moment().format(DATE_FMT.DATETIME_FIELD)));
      const preFetchRenderDate = getScheduleRenderDate(getState());
      const response = await api.availability.list(params, abortController);
      const stillValid =
        getScheduleRenderDate(getState()) === preFetchRenderDate;
      if (!stillValid) {
        return null;
      }
      dispatch(setList(response.data));
      return response;
    } catch (error: any) {
      if (axios.isCancel(error)) {
        return undefined;
      }
      dispatch(setListFetchedAt(undefined));
      dispatch(setListFetchState(FETCH_STATE.FAILED));
      logger.captureAxiosError('Error fetching availability', error);
      return undefined;
    }
  };

export default {
  reducer: Slice.reducer,
  initialState,
  name,
};
